.static-page-wrapper {
  position: relative;
  padding: 80px 0;
}

/*
.page-header {
padding-top: 50px;
padding-bottom: 50px;
background-color: #065697;
h1 {
padding-top: 50px;
color: white;
font-size: 47px;
}

h6 {
color: white;
font-size: 18px;
line-height: 25px;
}
}
*/

.page-header {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #065697;
}
.page-header h1 {
  padding-top: 50px;
  color: white;
  font-size: 47px;
}

.page-header  h6 {
  color: white;
  font-size: 18px;
  line-height: 25px;
}

.static-container {
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 0 x; */
}

.static-banner {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #065697;
}

.description {
  padding: 40px 10px;
  p {
    padding: 10px 0;
    font-size: 18px;
  }
  h3 {
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
  }
}

.primary-button {
  padding: 15px 30px;
  margin: 5px;
  background-color: #065697;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  border: 2px solid #065697;
}

.primary-button:hover {
  background-color: white;
  border: 2px solid #065697;
  color: #065697;
  font-weight: 500;
}

.list-data {
  padding: 10px 0px;
}
.list-data li {
  padding: 10px 0px;
  font-size: 18px;
}
.list-data li::before {
  content: "\2713" !important; /* Unicode for tick sign */
  display: inline-block;
  margin-right: 7px;
  /* margin-left: -40px; */
  padding-right: 5px;
  font-size: 1.3em;
  vertical-align: middle;
  color: #2490eb;
  font-weight: 600;
}


@media only screen and (max-width: 1350px) {
  .primary-button {
    display: block;
    text-align: center;
    max-width: 300px;
    margin: auto;
    margin-bottom: auto;
    margin-bottom: 10px;
  } 
  .page-header {
    width: 100%;
    padding-top: 0;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .primary-button {
    display: block;
    text-align: center;
    max-width: 300px;
    margin: auto;
    margin-bottom: auto;
    margin-bottom: 10px;
  } 
  .page-header {
    padding-top: 0;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .primary-button {
    display: block;
    text-align: center;
    max-width: 300px;
    margin: auto;
    margin-bottom: auto;
    margin-bottom: 10px;
  } 
  .page-header {
    padding-top: 0;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .primary-button {
    display: block;
    text-align: center;
    max-width: 300px;
    margin: auto;
    margin-bottom: auto;
    margin-bottom: 10px;
  } 
  .page-header {
    padding-top: 0;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .primary-button {
    display: block;
    text-align: center;
    max-width: 300px;
    margin: auto;
    margin-bottom: auto;
    margin-bottom: 10px;
  } 
  .page-header {
    padding-top: 0;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .primary-button {
    display: block;
    text-align: center;
    max-width: 300px;
    margin: auto;
    margin-bottom: auto;
    margin-bottom: 10px;
  } 
  .page-header {
    padding-top: 0;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-area .bottom-btn {
    display:none;
  }
}