article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
form {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: normal;
}

button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  border: none;
}

.custfield:focus {
  outline: none;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

body {
  font-size: 14px;
  color: #000000;
  font-family: "Inter", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 400;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

img {
  border: none;
  vertical-align: middle;
}

/****************
 * Common Css
 ****************/
header,
section,
footer {
  width: 100%;
  float: left;
}

.custfield {
  border: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

a,
a:hover,
.submenus,
img,
button,
i {
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.container {
  width: 1200px;
}

header {
  padding: 15px 0;
}

header .container-fluid {
  padding: 0 40px;
}

header .logo {
  float: left;
  width: 207px;
}

header .logo img {
  width: 100%;
}

.sticky {
  background-color: #ffffff;
  z-index: 2;
}

.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
  /* box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1); */
}

header .rpart {
  float: right;
  padding: 16px 0;
}

header .rpart .bttn-box {
  float: right;
}

header .rpart .custnav {
  float: left;
  padding: 16px 0;
}

header .rpart .custnav .menus li {
  display: inline-block;
  padding-right: 45px;
  position: relative;
}

header .rpart .custnav .menus li a {
  font-size: 19px;
  color: #222222;
}

header .rpart .custnav .menus li a:hover {
  color: #2ea3f2;
}

header .rpart .custnav .menus li a.active {
  color: #065697;
}

header .rpart .call-now {
  min-width: 210px;
  margin-bottom: 15px;
}

header .rpart .member {
  float: left;
  /* background: #065697;/ */
  font-size: 19px;
  padding: 16px 20px;
  border: 1px solid #065697;
}

header .rpart .member:hover {
  background-color: #065697;
  color: #ffffff;
}

.main-area {
  padding-top: 30px;
}

.main-area .custome1 {
  float: left;
  width: 62.8%;
  padding-top: 145px;
}

.main-area .custome2 {
  float: left;
  width: 36.7%;
}

.main-area .custome2 img {
  width: 100%;
}

.main-area h1 {
  /* font-size: 60px; */
  font-weight: 600;
  line-height: 1.3em;
  color: #1d1d1d;
}

.main-area h4 {
  /* font-size: 32px; */
  color: #1d1d1d;
  line-height: 1.3em;
  margin-bottom: 41px;
}

.main-area .bottom-btn a {
  display: inline-block;
  font-size: 18px;
  color: #ffffff;
  padding: 18px 30px;
  background: #065697;
  border-color: 1px solid #065697;
  margin-right: 20px;
}

.mor-information {
  background-color: #065697;
  padding: 71px 0 80px;
  text-align: center;
}

.mor-information h3 {
  font-size: 40px;
  color: #ffffff;
  padding-bottom: 46px;
  font-weight: 500;
}

.mor-information a {
  font-size: 18px;
  color: #ffffff;
  display: inline-block;
  padding: 18px 30px;
  border: 1px solid #ffffff;
}

.mor-information a:hover {
  background-color: #ffffff;
  color: #065697;
}

.content-box {
  padding: 60px 0 40px;
}

.content-box p {
  font-size: 17px;
  color: #222222;
  margin-bottom: 19px;
}

.content-box .image-box {
  width: 100%;
  padding: 15px;
}

.content-box .image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.benifits {
  padding: 40px 0 75px;
}

/* .benifits .crow {
    margin: 0 -25px;
} */

.benifits .crow {
  margin: 0 0px;
}

.benifits .p15 {
  padding: 0 25px;
}

.benifits .title {
  text-align: center;
  padding-bottom: 60px;
}

.benifits .title h2 {
  font-size: 32px;
  line-height: 1.3em;
  font-weight: 500;
}

.benifits .box {
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px 15px 30px;
  min-height: 194px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.benifits .box .icon {
  width: 70px;
  margin: 0 auto 20px;
}

.benifits .box .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.benifits .box label {
  font-size: 17px;
  color: #222222;
  font-weight: 400;
}

.who-serve {
  padding-top: 60px;
  padding-bottom: 40px;
  background-color: #f4f6f9 !important;
}

.who-serve .ctitle {
  padding-bottom: 30px;
}

.who-serve .ctitle h2 {
  font-size: 48px;
  line-height: 1.2em;
  color: #1d1d1d;
  font-weight: 500;
}

.who-serve .item {
  position: relative;
}

.who-serve .item .w-box {
  background-color: #ffffff;
  padding: 25px;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
}

.who-serve .item .w-box .tbox {
  float: left;
  width: 100%;
}

.who-serve .item .w-box .tbox svg {
  float: left;
  font-size: 53px;
  color: #065697;
}

.who-serve .item .w-box:hover .tbox svg {
  transform: scaleX(-1);
}

.who-serve .item .w-box .tbox span {
  float: right;
  line-height: 60px;
  font-size: 64px;
  font-weight: 600;
  color: rgba(33, 37, 41, 0.2);
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.who-serve .item .w-box:hover .tbox span {
  transform: translateX(-25px);
}

.who-serve .item .w-box label {
  float: left;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 14px;
  font-size: 22px;
  font-weight: 600;
}

.who-serve .item .w-box p {
  font-size: 16px;
  line-height: 1.6em;
  color: #222222;
  min-height: 355px;
}

.who-serve .item .w-box a {
  display: inline-block;
  margin-top: 25px;
  color: #065697;
  text-transform: uppercase;
  font-size: 16px;
}

.who-serve .owl-carousel {
  position: relative;
}

.who-serve .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.who-serve .owl-nav .owl-prev {
  left: -80px;
}

.who-serve .owl-nav .owl-prev span,
.who-serve .owl-nav .owl-next span {
  background-color: #ffffff;
  color: #0c71c3;
  font-size: 3rem;
  width: 53px;
  height: 53px;
  left: -53px;
  text-align: center;
  line-height: 53px;
  padding: 0px 18px;
}

.who-serve .owl-nav .owl-next {
  right: -80px;
}

.who-serve .owl-carousel .owl-nav.disabled,
.who-serve .owl-carousel .owl-dots.disabled {
  display: block;
}

.sign-up {
  padding: 30px 0 60px;
}

.sign-up .w47 {
  float: left;
  /* width: 47.25%; */
}

.sign-up .m5 {
  margin-right: 5.5%;
}

.sign-up .img-box {
  width: 100%;
}

.sign-up .img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sign-up .sign-text {
  padding: 5px 27px;
}

.sign-up .sign-text h3 {
  font-size: 48px;
  padding-bottom: 25px;
  font-weight: 500;
}

.sign-up .sign-text p {
  font-size: 17px;
  line-height: 1.6em;
  color: #222222;
  margin-bottom: 5.82%;
}

.sign-up .sign-text a {
  font-size: 18px;
  padding: 18px 40px;
  border: 1px solid #065697;
  color: #065697;
  display: inline-block;
}

.sign-up .sign-text a:hover {
  background-color: #065697;
  color: #ffffff;
}

.footer {
  background-color: #f7f7f7;
  padding: 50px 0 50px;
}

.footer .footer-widget {
  float: left;
  /* width: 20.875%; */
  margin-right: 4.5%;
}

.footer .footer-widget .flogo {
  width: 100%;
}

.footer .footer-widget .flogo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer .footer-widget h4 {
  font-size: 19px;
  color: #222222;
  line-height: 1.2em;
  margin-bottom: 27px;
  display: inline-block;
  font-weight: 500;
}

.footer .about p {
  font-size: 16px;
  color: #222222;
}

.footer .form-group input {
  height: 49px;
  border: 1px solid #bbbbbb7d;
}

.footer form .cbtn {
  padding: 8px 20px;
  background-color: #065697;
  border: 1px solid #065697;
  line-height: 1.5;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 18px;
}

.footer form .cbtn:hover {
  background-color: #23baed;
  border: 1px solid #23baed;
  color: #fff;
}

.footer .contactus {
  margin-right: 0;
}

.footer .contactus li p {
  margin-bottom: 23px;
  font-size: 16px;
  color: #000000;
}

.footer .contactus li a {
  float: left;
  font-size: 30px;
  margin-right: 25px;
  color: #222222;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.footer .contactus li a:hover {
  margin-top: -10px;
}

.footer-bottom {
  background-color: #065697;
  padding: 10px 0;
}

.footer-bottom .fmenu {
  float: left;
}

.footer-bottom .fmenu li {
  display: inline-block;
}

.footer-bottom .fmenu li a {
  font-size: 16px;
  color: #ffffff;
  line-height: 40px;
  padding: 0 9px;
}

.footer-bottom .rpart {
  float: right;
}

.footer-bottom .rpart p {
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
}

.footer-bottom .rpart p a {
  color: #ffffff;
}

.footer-bottom .rpart p a:hover {
  color: #08c;
}

/* Register */
.breadcrumbs {
  position: relative;
  background-image: url("../images/Long-Island-Marketing-Firm-request-consultation.jpg");
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.breadcrumbs::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.7);
}

.breadcrumbs h1 {
  padding: 11% 0 11%;
  font-size: 46px;
  font-weight: 700;
  color: #ffffff;
  z-index: 1;
  position: relative;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.formpart {
  padding: 60px 0;
}

.formpart .title {
  text-align: center;
  /* padding-bottom: 60px; */
}

.formpart .title h2 {
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  padding-bottom: 10px;
}

.formpart .title p {
  font-size: 17px;
}

.formpart .customeinput {
  height: 50px;
  border: 1px solid #bbbbbb7d;
  border-radius: 5px;
}

.formpart .form-group {
  margin-bottom: 18px;
}

.formpart .form-group label {
  font-size: 16px;
  color: #222222;
  margin-bottom: 8px;
}

.formpart .form-group span {
  font-size: 15px;
  padding-top: 5px;
  color: #222222;
  display: inline-block;
}

.formpart .form-group label i {
  font-size: 13px;
  color: #c02b0a;
  font-weight: 400;
}

.formpart .textarea {
  border: 1px solid #bbbbbb7d;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
}

.formpart .custome-btn {
  padding: 8px 20px;
  background-color: #065697;
  border: 1px solid #065697;
  line-height: 1.5;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 18px;
}

.formpart .custome-btn:hover {
  background-color: #23baed;
  border: 1px solid #23baed;
  color: #fff;
}

.who-serve .owl-nav .owl-next {
  right: -68px !important;
}

.who-serve .owl-nav .owl-prev {
  left: -68px !important;
}

.dark\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: unset !important;
}

.dark\:text-white {
  --tw-text-opacity: 1;
  color: unset !important;
}

.head-shadow {
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) !important;
}

.dialog-class {
  background-color: gray;
  opacity: 0.7;
}

.login-input-span {
  border-radius: 12px 0px 0px 12px;
}

.login-input {
  border-radius: 0px 12px 12px 0px;
}

.bg-primary-500 {
  background-color: #065697 !important;
}

.text-primary-500 {
  color: #065697 !important;
}

.input-disabled input {
  border: none;
  background-color: transparent;
  /* Add any additional styles for disabled input fields */
}

.collapse {
  visibility: visible !important;
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  color: white;
  font-size: 24px;
}

a {
  text-decoration: none !important;
}

.steps span {
  padding-bottom: 10px !important;
  padding-top: 10px;
  margin-left: 5px;
}

.active-step p {
  color: #0c71c3 !important;
}

.payment-input {
  height: 40px;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid transparent;
  font-size: 0.875rem;
  background-color: rgb(250, 251, 253);
  transition: border-color var(--transition), box-shadow var(--transition);
}

.rpart dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0 !important;
}

.rpart p {
  margin-top: 0;
  margin-bottom: 0 !important;
}


/* Custom Css for Header  */
/* Main header styles */
.main-header {
  position: relative;
}

.main-header.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

/* Flex and width utilities */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.md:flex-row-reverse {
  flex-direction: row-reverse;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.w-4 {
  width: 66.666667%;
}

.md:w-52 {
  width: 13rem;
  /* 52 divided by 4 */
}

.md:justify-between {
  justify-content: space-between;
}

/* Button and link styles */
.member {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.call-now {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.md:hidden {
  display: none;
}

.inline-flex {
  display: inline-flex;
}

.bttn-box {
  padding-left: 0;
}

.pl-0 {
  padding-left: 0;
}

.md:pl-2 {
  padding-left: 0.5rem;
}

/* Dropdown and account styles */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.text-xl {
  font-size: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.border {
  border-width: 1px;
}

.border-secondaryColor {
  border-color: #ffed4a;
  /* Replace with your secondary color */
}

.focus:outline-none:focus {
  outline: none;
}

.hover:bg-secondaryColor:hover {
  background-color: #ffed4a;
  /* Replace with your secondary color */
}

.w-full {
  width: 100%;
}

.mt-2 {
  margin-top: 0.5rem;
}

.w-48 {
  width: 12rem;
}

.bg-white {
  background-color: white;
}

.border-gray-300 {
  border-color: #d1d5db;
  /* Tailwind gray-300 */
}

.rounded-md {
  border-radius: 0.375rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.block {
  display: block;
}

.text-left {
  text-align: left;
}

.text-gray-800 {
  color: #1f2937;
  /* Tailwind gray-800 */
}

.hover:bg-gray-100:hover {
  background-color: #f7fafc;
  /* Tailwind gray-100 */
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

/* Navigation styles */
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}

#mobilemenu {
  display: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .md:hidden {
    display: block;
  }

  .flex-col {
    flex-direction: column;
  }

  #mobilemenu {
    display: block;
  }

  .menus {
    display: none;
  }

  .menus[style*="display: block"] {
    display: block;
  }
}

/* Custom classes for responsiveness */
@media (min-width: 768px) {
  .md:flex-row {
    flex-direction: row;
  }

  .md:justify-between {
    justify-content: space-between;
  }

  .md:w-52 {
    width: 13rem;
    /* 52 divided by 4 */
  }

  .md:pl-2 {
    padding-left: 0.5rem;
  }
}