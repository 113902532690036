.signatureDemo {
    text-align: center;
    margin-top: 30px;
}

​ .signatureDemo button {
    padding: 10px 20px;
    background-color: inherit;
    border: 1px solid gray;
}

​ .signatureDemo button:hover {
    background-color: gray;
    color: white;
}

​ .modalContainer {
    position: fixed;
    width: 100vw;
    height: 100%;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
}

​.modalContainer .modal {
    width: 90%;
    max-width: 500px;
    border: 5px;
    padding: 10px;
    background-color: white;
    box-sizing: border-box;
}

​ .modal__bottom {
    text-align: right;
    margin-top: 10px;
}

​ .modal__bottom button {
    padding: 6px 10px;
}

.sigPadContainer {
    background-color: rgb(238, 235, 235);
    padding: 0 10px 10px;
}

.sigCanvas {
    width: 40rem;
    height: 200px;
}

.sigPadContainer hr {
    margin-bottom: unset;
    background-color: gainsboro;
}

.sigPadContainer button {
    border: none;
    margin-left: auto;
    color: rgb(78, 75, 75);
    padding: 0;
    display: block;
    margin-top: 5px;
}

.sigPadContainer button:hover {
    background-color: unset;
    color: black;
}

.sigPad__penColors {
    margin-bottom: 10px;
}

​ .sigPad__penColors p {
    display: inline-block;
    margin-right: 5px;
}

​ .sigPad__penColors span {
    padding: 0px 9px;
    border-radius: 100%;
    margin-right: 5px;
}

.signature {
    width: 200px;
    height: 70px;
    margin-top: 20px;
    /* box-shadow: 0 0 4px 2px gainsboro; */
    padding: 10px;
    object-fit: contain;
    border: 1px solid black;
}

.info-table {
    margin-bottom: 10px;
}

.info-table tr td {
    width: 50% !important;
    padding-bottom: 10px !important;
}

.info-table tr td b {
    font-size: 16px;
}

.info-table tr td span {
    font-size: 18px;
}

.signature-title {
    margin-top: 2px !important;
    margin-bottom: 0px !important;
    color: #817979 !important;
    font-size: 20px !important;
    font-weight: 700 !important;
}

table tr td {
    border: none;
}

a.sign-footer {
    text-decoration: underline !important;
}

table.main-table {
    border: 2px solid black !important;
}

@media only screen and (max-width: 479px) {

    .signature-dialog.fixed,
    .signature-dialog .fixed {
        position: fixed !important;
    }

    .background-dialog {
        width: 90% !important;
    }

    .sigPadContainer {
        width: 250px;
    }

    .sigPadContainer canvas {
        width: 250px;
    }
}