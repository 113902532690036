@media only screen and (max-width: 1350px) {
    .benifits .crow {
        margin: 0 -15px;
    }

    .who-serve .owl-carousel .owl-nav.disabled,
    .who-serve .owl-carousel .owl-dots.disabled {
        display: none;
    }

    .who-serve .owl-nav {
        display: none;
    }
}

@media only screen and (max-width: 1199px) {
    .container {
        width: 970px;
    }

    header .rpart .custnav .menus li {
        padding-right: 21px;
    }

    header .rpart .custnav .menus li a,
    header .rpart .member {
        font-size: 18px;
    }

    header .rpart {
        padding: 18px 0;
    }

    header .rpart .member {
        padding: 12px 20px;
        margin-top: 4px;
    }

    .main-area h1 {
        font-size: 42px;
    }

    .main-area h4 {
        font-size: 28px;
    }

    .footer-bottom .fmenu li a {
        padding: 0 6px;
        text-transform: uppercase;
    }

    .footer-bottom .rpart p,
    .footer-bottom .fmenu li a {
        font-size: 15px
    }
}

@media only screen and (max-width: 1024px) {

    .who-serve .owl-carousel .owl-nav.disabled,
    .who-serve .owl-carousel .owl-dots.disabled {
        display: none !important;
    }
}

@media only screen and (max-width: 991px) {
    .container {
        width: 750px;
    }

    #mobilemenu {
        background: url('../images/menu-icon.png') no-repeat 50% 50%;
        width: 52px;
        height: 52px;
        cursor: pointer;
        margin-top: 4px;
        background-color: #20333f;
        background-position: 0%
    }

    #mobilemenu::after {
        content: "Page Menu";
        display: block;
        color: #fff;
        font-size: 18px;
        padding-top: 12px;
        padding-left: 35px;
        padding-bottom: 12px;
    }

    header .rpart .custnav {
        padding: 0;
        /* margin-right: 15px; */
    }

    .custnav .menus {
        position: absolute;
        display: none;
        top: 57px;
        left: 0px;
        right: 15px;
        width: 200px;
        background-color: #ffffff;
        padding-top: initial;
        z-index: 11;
        padding: 0 15px 5px;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
    }

    .custnav .menus>li {
        float: left;
        width: 100%;
        margin: 0;
        border-bottom: 1px solid #d2d2d2;
        padding: 6px 0;
        position: relative;
        text-transform: uppercase;
    }

    .custnav .menus>li:last-child,
    .custnav .menus>li:nth-last-child(2) {
        border-bottom: none;
    }

    .main-area .custome1 {
        text-align: center;
        padding: 25px;
    }

    .main-area h4 {
        margin-bottom: 25px;
    }

    .main-area .custome1,
    .main-area .custome2 {
        width: 100%;
    }

    .main-area .custome2 .image-box {
        width: 300px;
        margin: 0 auto;
    }

    .main-area .bottom-btn a {
        padding: 15px 20px;
        font-size: 16px;
        text-align: center;
    }

    .mor-information {
        padding: 50px 0;
    }

    .mor-information h3 {
        font-size: 30px;
        padding-bottom: 25px;
    }

    .content-box .image-box {
        padding: 0px;
    }

    .benifits {
        padding: 30px 0 65px;
    }

    .benifits .p15 {
        padding: 0 15px;
    }

    .benifits .box {
        margin: 15px 0;
    }

    .benifits .title {
        padding-bottom: 25px;
    }

    .who-serve .item .w-box p {
        min-height: 381px;
    }

    .sign-up .w47 {
        width: 100%;
    }

    .sign-up .m5 {
        margin-left: 0;
    }

    .sign-up .img-box {
        margin-bottom: 25px;
        padding: 0 25px;
    }

    .sign-up .sign-text p {
        margin-bottom: 25px;
    }

    .footer {
        padding-bottom: 15px;
    }

    .footer .footer-widget {
        width: 45.8%;
        margin: 0 15px;
        min-height: 150px;
        margin-bottom: 35px;
    }

    .footer-bottom .fmenu {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .footer-bottom .rpart {
        width: 100%;
        text-align: center;
    }

    .who-serve .owl-carousel .owl-nav.disabled,
    .who-serve .owl-carousel .owl-dots.disabled {
        display: none !important;
    }
}

@media only screen and (max-width: 767px) {
    .container {
        width: auto;
        padding: 0 15px;
    }

    header .container-fluid {
        padding: 0 15px;
    }

    header .logo {
        width: 120px;
        /* width: auto; */
        text-align: center;
    }

    header .logo a {
        width: 350px;
        display: inline-block;
    }

    .benifits .p15 {
        width: 50%;
        float: left;
    }

    .benifits .title h2 {
        font-size: 26px;
    }

    .who-serve .owl-nav button {
        top: initial;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
    }

    .who-serve .owl-carousel .owl-nav.disabled,
    .who-serve .owl-carousel .owl-dots.disabled {
        display: block;
    }

    .who-serve .owl-nav .owl-prev {
        left: 40%;
    }

    .who-serve .owl-nav .owl-next {
        right: 40%;
    }

    .footer .footer-widget {
        width: 100%;
        margin-left: initial;
        margin-right: initial;
    }

    .footer .footer-widget .flogo {
        width: 300px;
    }

    .footer .footer-widget {
        min-height: initial;
    }

    header .rpart {
        width: 100%;
    }

    header .rpart li {
        width: 100%;
        float: left;
        text-align: center;
    }

    header .rpart .member {
        /* display: inline-block; */
        float: initial;
        min-width: 300px;
        /* background-color: #08c; */
        background-color: #065697;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        color: #ffffff;
        border-radius: 50px;
        border: initial;
    }

    header .rpart .call-now {
        min-width: 210px;
        margin-bottom: 15px;
    }

    header .rpart .bttn-box {
        float: left;
        width: 100%;
    }

    header .rpart {
        padding-bottom: 0;
    }

    header .rpart .custnav {
        width: 100%;
        /* margin-top: 15px; */
        position: relative;
    }

    .custnav .menus {
        top: 55px;
        width: 100%;
        left: 0;
        right: 0;
        box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.1);
    }

    #mobilemenu {
        width: 100%;
    }

    header .rpart .custnav .menus li {
        text-align: left;
    }

    .who-serve .owl-carousel .owl-nav.disabled,
    .who-serve .owl-carousel .owl-dots.disabled {
        display: none !important;
    }

}

@media only screen and (max-width: 639px) {
    header .container-fluid {
        padding: 0 15px;
    }

    header .logo a {
        width: 300px;
    }

    .main-area .custome1 {
        margin-bottom: 25px;
    }

    .main-area h1 {
        font-size: 30px;
    }

    .main-area h4 {
        font-size: 26px;
    }

    .mor-information h3 {
        font-size: 27px;
    }

    .who-serve .ctitle h2,
    .sign-up .sign-text h3 {
        font-size: 28px;
    }

    .main-area .custome1 {
        padding: 0;
    }

    .who-serve .owl-carousel .owl-nav.disabled,
    .who-serve .owl-carousel .owl-dots.disabled {
        display: none;
    }

    .who-serve .item .w-box p {
        min-height: auto;
    }

    .who-serve .owl-carousel .owl-nav.disabled,
    .who-serve .owl-carousel .owl-dots.disabled {
        display: none !important;
    }

    .main-area .bottom-btn a {
        padding: 14px 14px;
        text-align: center;
    }
}

@media only screen and (max-width: 479px) {
    .benifits .p15 {
        width: 100%;
    }

    .main-area .bottom-btn a {
        width: 100%;
        /* margin: 0 0 15px; */
    }

    .who-serve .owl-carousel .owl-nav.disabled,
    .who-serve .owl-carousel .owl-dots.disabled {
        display: none !important;
    }

    .fixed {
        position: sticky !important;
    }

    .clinicians.fixed {
        position: fixed !important;
        display: grid !important;
    }
}